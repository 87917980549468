import React, { useContext } from "react";
import { AppContext } from "../../context";

import SprLogo from "../images/logo.png";

export const Header = ({ goToTopIos, goToAndroid }) => {
  const { isAndroid } = useContext(AppContext);
  const onClick = isAndroid ? goToAndroid : goToTopIos;

  return (
    <header>
      <div className='logo-cont'>
        <img src={SprLogo} alt='' className='appLogo' />
      </div>
      <p>
        <label class='appNamePusher'>直播管家</label>app，17岁以下禁入！
      </p>
      <a class='js_down' href='javascript:;' onClick={onClick}>
        下载APP
      </a>
    </header>
  );
};
