import React, { useContext } from "react";

import Bg0111Png from "../images/bg_0111.png";
import Bg0111Webp from "../images/bg_0111.webp";

import SprButtonsPng from "../images/spr-buttons.png";
import SprButtonsWebp from "../images/spr-buttons.webp";

import ArrowDown from "../images/arrow-down.svg";

import NewBgPng from "../images/newBg.png";
import NewBgWebp from "../images/newBg.webp";

import Bg02Png from "../images/bg_02.png";
import Bg02Webp from "../images/bg_02.webp";
import { AppContext } from "../../context";

export const TestFlightIOS = ({ goToIos, goToAndroid }) => {
  const { isAndroid, isiOS } = useContext(AppContext);

  return (
    <>
      {isiOS && (
        <>
          <section className="test_flight ios tf_down">
            <div>
              <picture>
                <source id="tf_bg" type="image/webp" srcSet={Bg0111Webp} />
                <img id="tf_bg" src={Bg0111Png} style={{ width: "100%" }} />
              </picture>
            </div>
            <a
              id="tf_btn_2_ios"
              className=""
              target="_blank"
              href="https://apps.apple.com/cn/app/id899247664"
            >
              <picture>
                <source
                  type="image/webp"
                  srcSet={SprButtonsWebp}
                  style={{ width: "4.074rem" }}
                />
                <img src={SprButtonsPng} style={{ width: "4.074rem" }} />
              </picture>
            </a>
            <a id="tf_btn_3_ios" className="" target="_blank" href="void(0)">
              <button className="orange-btn" onClick={goToIos}>
                <img src={ArrowDown} width="25px" />
                下载小红帽直播APP
              </button>
            </a>
          </section>

          <section className="test_flight ios">
            <picture>
              <source type="image/webp" srcSet={Bg02Webp} />
              <img src={Bg02Png} width="100%" />
            </picture>
          </section>
        </>
      )}

      {isAndroid && (
        <section className="test_flight an">
          <picture>
            <source type="image/webp" srcSet={NewBgWebp} />
            <img src={NewBgPng} width="100%" />
          </picture>
          <a id="tf_btn_2" className="js_down">
            <button className="orange-btn" onClick={goToAndroid}>
              <img src={ArrowDown} width="25px" />
              下载小红帽直播APP
            </button>
          </a>
        </section>
      )}
    </>
  );
};
