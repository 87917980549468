import React, { useState, useContext } from "react";

import { FixedHeader } from "./FixedHeader";
import { Header } from "./Header";
import { Warning } from "./Warning";
import { Info } from "./Info";
import { Ladys } from "./Ladys";
import { FixedFooter } from "./FixedFooter";
import { Footer } from "./Footer";
import { Safe } from "../safe-page/Safe";
import { downloadApp } from "../utils";
import { AppContext } from "../context";

const androidTopAPK =
  "/download-link?type=audience&platform=android&position=top";
const androidAPK =
  "/download-link?type=audience&platform=android&position=bottom";
const iosTopIPA = "/download-link?type=audience&platform=ios&position=top";
const iosIPA = "/download-link?type=audience&platform=ios&position=bottom";

const downloadAndroidTopApp = () => downloadApp({ url: androidTopAPK });

const downloadAndroidApp = () => downloadApp({ url: androidAPK });

const downloadIosTopApp = () => downloadApp({ url: iosTopIPA });

const downloadIosApp = () => downloadApp({ url: iosIPA });

export const NewDownload = () => {
  const [isVerificationPassed, setIsVerificationPassed] = useState(false);
  const { isiOS } = useContext(AppContext);

  const onTopDownload = isiOS ? downloadIosTopApp : downloadAndroidTopApp;
  const onDownload = isiOS ? downloadIosApp : downloadAndroidApp;

  return (
    <Safe
      isVerificationPassed={isVerificationPassed}
      setIsVerificationPassed={setIsVerificationPassed}
    >
      <>
        <FixedHeader onTopDownload={onTopDownload} onDownload={onDownload} />
        <Header onDownload={onDownload} isiOS={isiOS} />
        <Warning />
        <Info onDownload={onDownload} downloadAndroidApp={downloadAndroidApp} />
        <Ladys />
        <Footer />
        <FixedFooter onDownload={onDownload} />
      </>
    </Safe>
  );
};
