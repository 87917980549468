import React from "react";

import { Box, Typography } from "@mui/material";

import FLady from "./assets/1-lady.png";
import SLady from "./assets/2-lady.png";
import TLady from "./assets/3-lady.png";
import FoLady from "./assets/4-lady.png";

import FLadyWebp from "./assets/1-lady.webp";
import SLadyWebp from "./assets/2-lady.webp";
import TLadyWebp from "./assets/3-lady.webp";
import FoLadyWebp from "./assets/4-lady.webp";

const ladysMap = [
  {
    src: FLady,
    webpSrc: FLadyWebp,
    title: "网红美女",
  },
  {
    src: SLady,
    webpSrc: SLadyWebp,
    title: "海量美女",
  },
  {
    src: TLady,
    webpSrc: TLadyWebp,
    title: "美女主播",
  },
  {
    src: FoLady,
    webpSrc: FoLadyWebp,
    title: "互动交友",
  },
];

export const Ladys = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        gap: "50px",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {ladysMap.map(({ src, webpSrc, title }) => (
        <Box
          key={title}
          width="calc(50% - 50px)"
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box sx={{ width: 104, height: 104 }}>
            <picture className="image">
              <source type="image/webp" srcSet={webpSrc} />
              <img style={{ width: "100%" }} src={src} />
            </picture>
          </Box>
          <Typography
            sx={{
              fontSize: "14px",
              color: "#000",
              textAlign: "center",
            }}
          >
            {title}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};
