import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Addr } from "./components/Addr";
import { Comment } from "./components/Comment";
import { Footer } from "./components/Footer";
import { Goddess } from "./components/Goddess";
import { Header } from "./components/Header";
import { Live } from "./components/Live";
import { SwiperView } from "./components/Swiper";
import { TestFlightIOS } from "./components/TestFlightIOS";

import SprButtons3 from "./images/spr-buttons3.png";

import "./styles.css";

export const DownloadPage = ({
  goToIos,
  goToAndroid,
  goToTopIos,
  letsChatUrl,
  githubUrl,
  pageTitle,
}) => {
  const [isGoTopBtnShown, setIsGoTopBtnShown] = useState(false);

  useEffect(() => {
    const onScroll = () => {
      if (window.pageYOffset > window.innerHeight) {
        if (!isGoTopBtnShown) {
          setIsGoTopBtnShown(true);
        }
      } else {
        setIsGoTopBtnShown(false);
      }
    };
    document.addEventListener("scroll", onScroll);

    return () => {
      document.removeEventListener("scroll", onScroll);
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <Header goToTopIos={goToTopIos} goToAndroid={goToAndroid} />
      <SwiperView />
      <Live />
      <Goddess />
      <a className="more js_pop down down_top">更多女神&gt;</a>
      <Comment />
      <TestFlightIOS goToIos={goToIos} goToAndroid={goToAndroid} />
      <Addr letsChatUrl={letsChatUrl} githubUrl={githubUrl} />
      <Footer goToIos={goToIos} goToAndroid={goToAndroid} />
      {isGoTopBtnShown && (
        <div
          className="go_top"
          onClick={() => {
            window.scrollTo({
              top: 0,
              left: 0,
              behavior: "smooth",
            });
          }}
        >
          <img src={SprButtons3} />
        </div>
      )}
    </>
  );
};
