import React, { useContext } from "react";

import { Box, Typography } from "@mui/material";

import WarningIcon from "@mui/icons-material/Warning";

import { AppContext } from "../context";

export const Warning = () => {
  const { isiOS } = useContext(AppContext);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        background: "#000",
        padding: "5px 0",
        width: "100%",
      }}
    >
      <Typography
        sx={{
          display: "flex",
          alignItems: "center",
          fontSize: "20px",
          fontWeight: 600,
          color: "#fff",
        }}
      >
        下载注意 <WarningIcon sx={{ fill: "#FFD317", width: 25 }} />
        <WarningIcon sx={{ fill: "#FFD317", width: 25 }} />
        <WarningIcon sx={{ fill: "#FFD317", width: 25 }} />
      </Typography>
      {isiOS ? (
        <Typography
          sx={{
            textAlign: "center",
            fontSize: "14px",
            color: "#fff",
            fontWeight: 500,
            padding: "0 29px",
          }}
        >
          请使用苹果手机系统自带Safari 浏览器，进行下载！
        </Typography>
      ) : (
        <Typography
          sx={{
            textAlign: "center",
            fontSize: "14px",
            color: "#fff",
            fontWeight: 500,
            padding: "0 56px",
          }}
        >
          如果浏览器报毒，无法下载小红帽直播app 请使用
          <span style={{ color: "#FFF389" }}>谷歌浏览器下载</span>
        </Typography>
      )}
    </Box>
  );
};
