import React from "react";

import { Box, Button } from "@mui/material";
import HeaderBg from "./assets/header.jpg";
import HeaderLogoBtn from "./assets/header_logo_btn.png";
import HeaderLogoBtnWebp from "./assets/header_logo_btn.webp";
import { ReactComponent as CallIcon } from "./assets/call.svg";
import { ReactComponent as Download } from "./assets/download.svg";

export const Header = ({ onDownload, isiOS }) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "space-between",
        background: `url(${HeaderBg}) no-repeat`,
        backgroundSize: "cover",
        height: 650,
      }}
    >
      <Box sx={{ width: 120, height: 110, marginTop: "100px" }}>
        <picture className="image">
          <source type="image/webp" srcSet={HeaderLogoBtn} />
          <img style={{ width: "100%" }} src={HeaderLogoBtnWebp} />
        </picture>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginBottom: "32px",
        }}
      >
        <Button
          sx={{
            background:
              "linear-gradient(147.85deg, #FF309A 2.78%, #FF5A6B 96.17%)",
            border: "2px solid #FFFFFF",
            borderRadius: "37px",
            color: "#fff",
            fontSize: "18px",
            fontFamily: "PingFang SC",
            width: 214,
            marginBottom: "12px",
          }}
          onClick={onDownload}
        >
          <Download />
          {isiOS ? "官方下载" : "安卓官方下载"}
        </Button>

        <Button
          sx={{
            background: "#fff",
            boxShadow: "0px 4px 16px rgba(61, 58, 59, 0.1)",
            borderRadius: "37px",
            color: "#FF3D8D",
            fontSize: "18px",
            fontFamily: "PingFang SC",
            width: 214,
            "&:hover, &:focus, &:active": {
              background: "#fff",
            },
          }}
          startIcon={<CallIcon />}
          onClick={() => {
            window.open("https://letschat.newweiwxx.com/index.html", "_blank");
          }}
        >
          联系客服
        </Button>
      </Box>
    </Box>
  );
};
