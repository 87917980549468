import React from "react";

import SprAvatarWebp from "../images/spr-avatar.webp";
import SprAvatarPng from "../images/spr-avatar.png";

export const Comment = () => {
  return (
    <section className="comment">
      <div className="titH">热门评论</div>
      <ul>
        <li>
          <a className="clearfix down">
            <span className="adver">
              <picture>
                <source type="image/webp" srcSet={SprAvatarWebp} />
                <img src={SprAvatarPng} />
              </picture>
            </span>
            <div className="tit">
              <h5>旅人与风</h5>
              <span>30秒前</span>
            </div>
            <p className="des">主播们都很给力，身材很棒~</p>
          </a>
        </li>
        <li>
          <a className="clearfix down">
            <span className="adver">
              <picture>
                <source
                  type="image/webp"
                  srcSet={SprAvatarWebp}
                  style={{ left: "calc(0.8rem * -1)" }}
                />
                <img src={SprAvatarPng} style={{ left: "calc(0.8rem * -1)" }} />
              </picture>
            </span>
            <div className="tit">
              <h5>我在机场等艘船</h5>
              <span>45秒前</span>
            </div>
            <p className="des">刚下载，流畅不卡</p>
          </a>
        </li>
        <li>
          <a className="clearfix down">
            <span className="adver">
              <picture>
                <source
                  type="image/webp"
                  srcSet={SprAvatarWebp}
                  style={{ left: "calc(0.8rem * -2)" }}
                />
                <img src={SprAvatarPng} style={{ left: "calc(0.8rem * -2)" }} />
              </picture>
            </span>
            <div className="tit">
              <h5>烈酒迷情</h5>
              <span>55秒前</span>
            </div>
            <p className="des">很会玩啊，涨姿势啊，感觉白活了二十几年</p>
          </a>
        </li>
        <li>
          <a className="clearfix down">
            <span className="adver">
              <picture>
                <source
                  type="image/webp"
                  srcSet={SprAvatarWebp}
                  style={{ left: "calc(0.8rem * -3)" }}
                />
                <img src={SprAvatarPng} style={{ left: "calc(0.8rem * -3)" }} />
              </picture>
            </span>
            <div className="tit">
              <h5>海边人</h5>
              <span>1分钟前</span>
            </div>
            <p className="des">一边看一边撸，简直是享受好吗</p>
          </a>
        </li>
        <li>
          <a className="clearfix down">
            <span className="adver">
              <picture>
                <source
                  type="image/webp"
                  srcSet={SprAvatarWebp}
                  style={{ left: "calc(0.8rem * -4)" }}
                />
                <img src={SprAvatarPng} style={{ left: "calc(0.8rem * -4)" }} />
              </picture>
            </span>
            <div className="tit">
              <h5>攒了一身酷</h5>
              <span>2分钟前</span>
            </div>
            <p className="des">哈哈，兔叽姐姐居然是跟我一个城市的</p>
          </a>
        </li>
      </ul>
    </section>
  );
};
