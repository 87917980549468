import React from "react";

import SprButtons3 from "../images/zhubo.png";
import Group1Webp from "../images/Group1.webp";
import Group1Png from "../images/Group1.png";

import Slice2Webp from "../images/Slice2.webp";
import Slice2Png from "../images/Slice2.png";

import Slice3Webp from "../images/Slice3.webp";
import Slice3Png from "../images/Slice3.png";

import Slice4Webp from "../images/Slice4.webp";
import Slice4Png from "../images/Slice4.png";

import Slice5Webp from "../images/Slice5.webp";
import Slice5Png from "../images/Slice5.png";

import Slice6Webp from "../images/Slice6.webp";
import Slice6Png from "../images/Slice6.png";
import { useState } from "react";
import { useEffect } from "react";

function random(lower, upper) {
  return Math.floor(Math.random() * (upper - lower)) + lower;
}

const t = Date.parse(new Date()) / 1000;

export const Live = () => {
  const [total, setTotal] = useState(Math.floor(t / 100));
  const [onlineA, setOnlineA] = useState(random(500, 800));
  const [onlineU, setOnlineU] = useState(onlineA * 1951 + random(200, 999));

  useEffect(() => {
    const intervalId = setInterval(function () {
      let ran = random(-10, 10);
      if (onlineA < 520) {
        ran = random(10, 40);
      } else if (onlineA > 790) {
        ran = random(-10, -40);
      }
      setOnlineA((prevState) => prevState + ran);
      setOnlineU((prevState) => prevState + ran * 1953 + random(200, 999));
      setTotal((prevState) => prevState + random(5, 20));
    }, 3000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <section className='live'>
      <div className='titH'>
        正在直播
        <div id='leibie'>
          <span
            style={{
              overflow: "hidden",
              marginTop: "0.093rem",
              position: "absolute",
              left: "-0.333rem",
            }}
          >
            <img
              src={SprButtons3}
              style={{
                position: "relative",
                top: "-1.741rem",
                width: "0.852rem",
              }}
            />
          </span>
          <span
            style={{
              marginLeft: "0.5rem",
              fontWeight: "bold",
            }}
          >
            在线主播:
          </span>
          <span className='online_a' style={{ color: "#ff3d83" }}>
            {onlineA}
          </span>
          &nbsp;&nbsp;|&nbsp;&nbsp;
          <span style={{ fontWeight: "bold" }}>在线会员:</span>
          <span className='online_u' style={{ color: "#ff3d83" }}>
            {onlineU}
          </span>
          &nbsp;&nbsp;|&nbsp;&nbsp;
          <span style={{ fontWeight: "bold" }}>会员总数:</span>
          <span className='total_u' style={{ color: "#ff3d83" }}>
            {total}
          </span>
        </div>
      </div>
      <ul>
        <li>
          <a className='down'>
            <span className='pic'>
              <picture className='picture'>
                <source type='image/webp' srcSet={Group1Webp} />
                <img className='image' src={Group1Png} />
              </picture>
              <span className='amount'>1373</span>
              <span className='tag tag1'></span>
              <span className='distance'>
                <span>0.4km</span>
              </span>
              <div className='userTagInfo'>
                <div className='trueuser_icon'></div>
                <div className=''></div>
              </div>
            </span>
            <div className='des'>
              <p className='name'>
                蔚蓝<span>21岁</span>
                <span className='online-bg'></span>
              </p>
              <p>哥哥来帮我揉揉吧~</p>
            </div>
          </a>
        </li>
        <li>
          <a className='down'>
            <span className='pic'>
              <picture className='picture'>
                <source type='image/webp' srcSet={Slice2Webp} />
                <img className='image' src={Slice2Png} />
              </picture>
              <span className='amount'>2607</span>
              <span className='tag tag1'></span>
              <span className='distance'>
                <span>1.4km</span>
              </span>
              <div className='userTagInfo'>
                <div className='trueuser_icon'></div>
                <div className=''></div>
              </div>
            </span>
            <div className='des'>
              <p className='name'>
                画眉<span>23岁</span>
                <span className='online-bg'></span>
              </p>
              <p>喜欢我的PP吗？</p>
            </div>
          </a>
        </li>
        <li>
          <a className='down'>
            <span className='pic'>
              <picture className='picture'>
                <source type='image/webp' srcSet={Slice3Webp} />
                <img className='image' src={Slice3Png} />
              </picture>
              <span className='amount'>1792</span>
              <span className='tag tag3'></span>
              <span className='distance'>
                <span>2.3km</span>
              </span>
              <div className='userTagInfo'>
                <div className='trueuser_icon'></div>
                <div className=''></div>
              </div>
            </span>
            <div className='des'>
              <p className='name'>
                宁夏<span>24岁</span>
                <span className='online-bg'></span>
              </p>
              <p>自摸~我糊了~</p>
            </div>
          </a>
        </li>
        <li>
          <a className='down'>
            <span className='pic'>
              <picture className='picture'>
                <source type='image/webp' srcSet={Slice4Webp} />
                <img className='image' src={Slice4Png} />
              </picture>
              <span className='amount'>1473</span>
              <span className='tag tag2'></span>
              <span className='distance'>
                <span>3.4km</span>
              </span>
              <div className='userTagInfo'>
                <div className='trueuser_icon'></div>
                <div className=''></div>
              </div>
            </span>
            <div className='des'>
              <p className='name'>
                暖暖<span>23岁</span>
                <span className='online-bg'></span>
              </p>
              <p>不知道你们晕不晕奶啊</p>
            </div>
          </a>
        </li>
        <li>
          <a className='down'>
            <span className='pic'>
              <picture className='picture'>
                <source type='image/webp' srcSet={Slice5Webp} />
                <img className='image' src={Slice5Png} />
              </picture>
              <span className='amount'>2991</span>
              <span className='tag tag3'></span>
              <span className='distance'>
                <span>2.5km</span>
              </span>
              <div className='userTagInfo'>
                <div className='trueuser_icon'></div>
                <div className=''></div>
              </div>
            </span>
            <div className='des'>
              <p className='name'>
                浅唱<span>21岁</span>
                <span className='online-bg'></span>
              </p>
              <p>哥哥们觉得我这衣服怎么样？</p>
            </div>
          </a>
        </li>
        <li>
          <a className='down'>
            <span className='pic'>
              <picture className='picture'>
                <source type='image/webp' srcSet={Slice6Webp} />
                <img className='image' src={Slice6Png} />
              </picture>
              <span className='amount'>2495</span>
              <span className='tag tag1'></span>
              <span className='distance'>
                <span>3.2km</span>
              </span>
              <div className='userTagInfo'>
                <div className='trueuser_icon'></div>
                <div className=''></div>
              </div>
            </span>
            <div className='des'>
              <p className='name'>
                小雪<span>22岁</span>
                <span className='online-bg'></span>
              </p>
              <p>是时候考虑去开出租车了</p>
            </div>
          </a>
        </li>
      </ul>
    </section>
  );
};
