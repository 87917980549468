import React, { useContext } from "react";

import { Box, Typography, Button } from "@mui/material";
import TestFlightButtonWebp from "./assets/test_flight_button.webp";
import TestFlightButton from "./assets/test_flight_button.png";
import IosButtonWebp from "./assets/ios_button.webp";
import IosButton from "./assets/ios_button.png";
import { ReactComponent as Download } from "./assets/download.svg";

import { AppContext } from "../context";

export const Info = ({ onDownload, downloadAndroidApp }) => {
  const { isiOS } = useContext(AppContext);

  if (isiOS) {
    return (
      <Box sx={{ padding: "30px 19px" }}>
        <Box marginTop="20px">
          <Typography
            sx={{
              fontSize: "20px",
              fontWeight: 600,
              color: "#060606",
            }}
          >
            1.安装 <span style={{ color: "#1F94E9" }}>IOS TestFlight</span>
          </Typography>
          <Typography
            sx={{
              fontSize: "14px",
              color: "#656565",
            }}
          >
            安装前，您需要先安装苹果官方{" "}
            <span style={{ color: "#1F94E9" }}>IOS TestFlight ！</span>
          </Typography>
          <Button
            sx={{ width: "100%" }}
            onClick={() => {
              window.open(
                "https://apps.apple.com/cn/app/id899247664",
                "_blank"
              );
            }}
          >
            <picture>
              <source type="image/webp" srcSet={TestFlightButton} />
              <img style={{ width: "100%" }} src={TestFlightButtonWebp} />
            </picture>
          </Button>
        </Box>
        <Box sx={{ marginTop: "10px" }}>
          <Typography
            sx={{
              fontSize: "20px",
              fontWeight: 600,
              color: "#060606",
            }}
          >
            2.安装小红帽APP
          </Typography>
          <Typography
            sx={{
              fontSize: "14px",
              color: "#656565",
              textAlign: "center",
            }}
          >
            当您安装完成<span style={{ color: "#1F94E9" }}>IOS TestFlight</span>
            后， <br /> 即可下载或升级【
            <span style={{ color: "#FF3D8D" }}>小红帽直播app </span>】！
          </Typography>
          <Button sx={{ width: "100%" }} onClick={onDownload}>
            <picture>
              <source type="image/webp" srcSet={IosButton} />
              <img style={{ width: "100%" }} src={IosButtonWebp} />
            </picture>
          </Button>
        </Box>
      </Box>
    );
  }

  return (
    <Box sx={{ padding: "30px 19px" }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          gap: "8px",
        }}
      >
        <Typography
          sx={{
            color: "#FF3D8D",
            fontSize: "18px",
            fontWeight: 600,
          }}
        >
          安卓手机出现限制安装解决方法
        </Typography>

        <Button
          sx={{
            background:
              "linear-gradient(147.85deg, #FF309A 2.78%, #FF5A6B 96.17%)",
            border: "2px solid #FFFFFF",
            borderRadius: "37px",
            color: "#fff",
            fontSize: "18px",
            fontFamily: "PingFang SC",
            width: 214,
            marginBottom: "12px",
          }}
          onClick={downloadAndroidApp}
        >
          <Download />
          安卓官方下载
        </Button>
      </Box>

      <Box marginTop="20px">
        <Typography
          sx={{
            fontSize: "20px",
            fontWeight: 600,
            color: "#060606",
          }}
        >
          1.VIVO手机
        </Typography>
        <Typography
          sx={{
            fontSize: "14px",
            color: "#656565",
          }}
        >
          请在设置{">"}隐私设置{">"}
          <span style={{ color: "#FF3D8D" }}>关闭vivo的系统检查功能</span>，
          即可正常安装！
        </Typography>
      </Box>
      <Box sx={{ marginTop: "10px" }}>
        <Typography
          sx={{
            fontSize: "20px",
            fontWeight: 600,
            color: "#060606",
          }}
        >
          2.其他安卓手机
        </Typography>
        <Typography
          sx={{
            fontSize: "14px",
            color: "#656565",
          }}
        >
          请在设置{">"}更多隐私{">"}安全{">"}
          <span style={{ color: "#FF3D8D" }}>关闭 “未知来源安装”！</span>
          即可正常安装！ 关闭vivo的系统检查功能 ， 即可正常安装！
        </Typography>
      </Box>
    </Box>
  );
};
