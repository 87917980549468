import React from "react";

import HeaderLogoPng from "./assets/header_logo.png";
import HeaderLogoWebp from "./assets/header_logo.webp";

import HeaderButtonPng from "./assets/header_button.png";
import HeaderButtonWebp from "./assets/header_button.webp";

import "./styles.css";

export const FixedHeader = ({ onTopDownload }) => {
  return (
    <div className="header_panel">
      <div className="header_panel__logo-wrapper">
        <a href="#" className="header_panel__logo">
          <picture className="image">
            <source type="image/webp" srcSet={HeaderLogoWebp} />
            <img className="image" src={HeaderLogoPng} />
          </picture>
        </a>
        <div className="header_panel__text">
          小红帽app <span style={{ display: "block" }}>您成年了吗？</span>
        </div>
      </div>
      <a href="#" className="header_panel__button" onClick={onTopDownload}>
        <picture className="image">
          <source type="image/webp" srcSet={HeaderButtonWebp} />
          <img className="image" src={HeaderButtonPng} />
        </picture>
      </a>
    </div>
  );
};
