import React from "react";
import { useState } from "react";
import { DownloadPage } from "../download-page/DownloadPage";
import { Safe } from "../safe-page/Safe";
import { downloadApp } from "../utils";

const androidUrl = "http://app-center.lccic.com/Audience/1.10-512.apk";
const iosTfUrl = "https://kuailabao.com/8rxz.app";

export const Audience = () => {
  const [isVerificationPassed, setIsVerificationPassed] = useState(false);

  return (
    <Safe
      isVerificationPassed={isVerificationPassed}
      setIsVerificationPassed={setIsVerificationPassed}
    >
      <DownloadPage
        goToIos={() => downloadApp({ url: iosTfUrl })}
        goToAndroid={() => downloadApp({ url: androidUrl })}
        goToTopIos={() => downloadApp({ url: iosTfUrl })}
        pageTitle="小红帽直播app_官方下载"
      />
    </Safe>
  );
};
