import React from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { Audience } from "./pages/Audience";
import { AudienceNew } from "./pages/AudienceNew";
import { Jingling } from "./pages/Jingling";
import { Xiaonaimao } from "./pages/Xiaonaimao";
import { Yujian } from "./pages/Yujian";
import { getMobileOS } from "./download-page/utils";
import { AppContext } from "./context";
import { NewDownload } from "./new-download-page/NewDownload";
import "amfe-flexible";

import { Pusher } from "./pages/Pusher";

const appRoutes = {
  xiaonaimao: "/xhm",
  audience: "/audience",
  audience_new: "/audience_new",
  jingling: "/jingling",
  yujian: "/yujian",
  new_download: "/new_download",
  pusher: "/pusher",
};

function App() {
  const mobileOS = getMobileOS();
  const { search } = useLocation();

  const isAndroid = mobileOS === "Android";
  const isiOS = mobileOS === "iOS";
  return (
    <AppContext.Provider value={{ isAndroid, isiOS }}>
      <Routes>
        <Route
          index
          element={<Navigate to={appRoutes.new_download + search} />}
        />
        <Route path={appRoutes.xiaonaimao} element={<Xiaonaimao />}>
          <Route path="*" element={<Xiaonaimao />} />
        </Route>
        <Route path={appRoutes.audience} element={<Audience />}>
          <Route path="*" element={<Audience />} />
        </Route>
        <Route path={appRoutes.audience_new} element={<AudienceNew />}>
          <Route path="*" element={<AudienceNew />} />
        </Route>
        <Route path={appRoutes.jingling} element={<Jingling />}>
          <Route path="*" element={<Jingling />} />
        </Route>
        <Route path={appRoutes.yujian} element={<Yujian />}>
          <Route path="*" element={<Yujian />} />
        </Route>

        <Route path={appRoutes.new_download} element={<NewDownload />}>
          <Route path="*" element={<NewDownload />} />
        </Route>

        <Route path={appRoutes.pusher} element={<Pusher />}>
          <Route path='*' element={<Pusher />} />
        </Route>

        <Route
          path="*"
          exact={true}
          element={<Navigate to={appRoutes.new_download + search} />}
        />
      </Routes>
    </AppContext.Provider>
  );
}

export default App;
