import React from "react";

import { Box, Typography, Button } from "@mui/material";

import WarningMessage from "./assets/warning_message.png";
import WarningMessageWebp from "./assets/warning_message.webp";

export const Footer = () => {
  return (
    <Box
      sx={{
        background: "linear-gradient(147.85deg, #FF309A 2.78%, #FF5A6B 96.17%)",
        padding: "56px 23px",
        marginTop: "35px",
      }}
    >
      <Box
        sx={{
          background: "rgba(255, 255, 255, 0.05)",
          border: "1px solid #FFFFFF",
          borderRadius: "4px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: "25px",
          padding: "6px 20px",
        }}
      >
        <Typography
          sx={{
            color: "#FFF389",
            fontSize: "24px",
            fontWeight: 600,
          }}
        >
          网址：mei9.app
        </Typography>
        <Button
          sx={{
            color: "#FF4189",
            width: "71px",
            height: "32px",
            borderRadius: "8px",
            backgroundColor: "#fff",
            border: "none",
            fontSize: "16px",
            padding: 0,
            "&:hover, &:focus, &:active": {
              backgroundColor: "#fff",
            },
          }}
          onClick={() => {
            navigator.clipboard.writeText("mei9.app");
          }}
        >
          复制
        </Button>
      </Box>
      <Typography
        sx={{
          color: "#fff",
          fontSize: "14px",
          textAlign: "center",
        }}
      >
        本站提供最新网址，在使用前请先收藏本站地址 真人互动交友，值得您收藏！
      </Typography>

      <Box sx={{ width: "100%", marginTop: "28px" }}>
        +
        <picture className="image">
          <source type="image/webp" srcSet={WarningMessage} />
          <img style={{ width: "100%" }} src={WarningMessageWebp} />
        </picture>
      </Box>
    </Box>
  );
};
