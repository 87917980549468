import React from "react";

import First from "../images/11.jpg";
import Second from "../images/12.jpg";
import Third from "../images/13.jpg";
import Fourth from "../images/14.jpg";
import Fifth from "../images/15.jpg";

export const Comment = () => {
  return (
    <section class='comment'>
      <div class='titH'>热门评论</div>
      <ul>
        <li>
          <a class='clearfix down'>
            <span class='advert'>
              <img src={First} />{" "}
            </span>
            <div class='tit'>
              <h5>烟酒情话</h5>
              <span>30秒前</span>{" "}
            </div>
            <p class='des'>很流畅，基本不卡！</p>
          </a>{" "}
        </li>
        <li>
          <a class='clearfix down'>
            <span class='advert'>
              <img src={Second} />{" "}
            </span>
            <div class='tit'>
              <h5>西岸风</h5>
              <span>45秒前</span>{" "}
            </div>
            <p class='des'>强烈推荐主播蔚蓝，放得开~好看~~</p>
          </a>{" "}
        </li>
        <li>
          <a class='clearfix down'>
            <span class='advert'>
              <img src={Third} />{" "}
            </span>
            <div class='tit'>
              <h5>公元反叛者</h5>
              <span>55秒前</span>{" "}
            </div>
            <p class='des'>大部分主播都很漂亮</p>
          </a>{" "}
        </li>
        <li>
          <a class='clearfix down'>
            <span class='advert'>
              <img src={Fourth} />{" "}
            </span>
            <div class='tit'>
              <h5>酷不停</h5>
              <span>1分钟前</span>{" "}
            </div>
            <p class='des'>昨天晚上跟美女聊到3点多，精神~</p>
          </a>{" "}
        </li>
        <li>
          <a class='clearfix down'>
            <span class='advert'>
              <img src={Fifth} />{" "}
            </span>
            <div class='tit'>
              <h5>零点过十分</h5>
              <span>2分钟前</span>{" "}
            </div>
            <p class='des'>朋友推荐下载的，不错，都很漂亮，敢说敢做</p>
          </a>{" "}
        </li>
      </ul>
    </section>
  );
};
