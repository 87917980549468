import React from "react";

import SprButtons from "../images/spr-buttons.png";

export const Addr = ({
  githubUrl = "https://github.com/XHM6/1",
  letsChatUrl = "https://letschat.newweiwxx.com/index.html",
}) => {
  return (
    <section className="addr">
      <div className="contact">
        <a className="down" rel="noreferrer" target="_blank" href={letsChatUrl}>
          <img
            src={SprButtons}
            style={{
              width: "5.963rem",
              position: "relative",
              top: "-2.963rem",
            }}
          />
        </a>
      </div>
      <div className="addr-content">
        <a target="_blank" rel="noreferrer" href={githubUrl}>
          <span>回家地址</span>
          <span className="warning">{githubUrl}</span>
        </a>
      </div>
    </section>
  );
};
