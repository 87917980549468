import React, { useContext } from "react";
import { AppContext } from "../../context";

import FooterLogoPng from "../images/logo.png";

export const Footer = ({ goToIos, goToAndroid }) => {
  const { isAndroid, isiOS } = useContext(AppContext);

  return (
    <footer>
      <div className='logo-cont'>
        <img src={FooterLogoPng} alt='' className='appLogo' />
      </div>
      <p>
        <span>直播管家</span>
        <span>您的女神还有5秒抵达战场 →</span>
      </p>
      {isAndroid && (
        <a onClick={goToAndroid} className='an js_down'>
          免费下载
        </a>
      )}
      {isiOS && (
        <a onClick={goToIos} className='ios js_down'>
          免费下载
        </a>
      )}
    </footer>
  );
};
