import React from "react";
import { useEffect } from "react";

import "./styles.css";

export const Safe = ({
  setIsVerificationPassed,
  isVerificationPassed,
  children,
}) => {
  useEffect(() => {
    setTimeout(function () {
      const icon = document.getElementById("icon");
      const iconText = document.getElementById("iconText");
      const subText = document.getElementById("subText");
      icon.classList.add("weui-icon-success");
      iconText.innerHTML = "已通过安全检测";
      subText.innerHTML = "如果没有自动跳转，请点击下方按钮前往";
    }, 1000);
  }, []);

  if (isVerificationPassed) {
    return children;
  }

  return (
    <>
      <div style={{ textAlign: "center", padding: "60px 10px 50px 10px" }}>
        <i className="weui-icon-waiting icon" id="icon"></i>
        <p style={{ fontSize: 24, color: "#555" }} id="iconText">
          正在检测...
        </p>
        <p style={{ color: "#999" }} id="subText"></p>
      </div>

      <div className="actions" id="goHome">
        <a
          className="weui-btn weui-btn_primary"
          onClick={() => setIsVerificationPassed(true)}
        >
          点击前往
        </a>
      </div>
      <div className="footer">
        <p>
          <a>电脑管家</a>为您护航
        </p>
        <p>Copyright © 1998 - 2020. All Rights Reserved.</p>
      </div>
    </>
  );
};
