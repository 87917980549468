import React from "react";
import { createTheme, ThemeProvider } from "@mui/material";
import ReactDOM from "react-dom/client";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import posthog from "posthog-js";
import { BrowserRouter } from "react-router-dom";

import App from "./App";
import { getRefereeId } from "./utils";

const theme = createTheme({
  typography: {
    fontFamily: ["PingFang SC"].join(","),
  },
});

Sentry?.init({
  dsn: "https://017e8f00693a4308a6e26f3701213d9a@o4504050208473088.ingest.sentry.io/4504050336727040",
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

posthog.init("phc_K1JCCmFUISJuJwzpoNpPmVKhXQyMbM1OQAABWS6YOWN", {
  api_host: "https://metrics.telarycover.io",
  loaded: function (ph) {
    ph.register({
      referee_id: getRefereeId(),
    });
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </BrowserRouter>
  </React.StrictMode>
);
