import React, { useContext } from "react";
import { AppContext } from "../../context";

import SprLogo from "../images/spr-logo.svg";

export const Header = ({ goToTopIos, goToAndroid }) => {
  const { isAndroid } = useContext(AppContext);
  const onClick = isAndroid ? goToAndroid : goToTopIos;

  return (
    <header>
      <div className="logo-cont">
        <img src={SprLogo} alt="" className="appLogo" />
      </div>
      <p className="appName">
        <label>小红帽直播</label>app，18岁以下禁入！
      </p>
      <a className="down_top" onClick={onClick}>
        下载APP
      </a>
    </header>
  );
};
