import React from "react";
import { useState } from "react";
import { DownloadPage } from "../download-page/DownloadPage";
import { Safe } from "../safe-page/Safe";
import { downloadApp } from "../utils";

const androidUrl = "http://app.gaga123.com/Audience/1.11.1-XHM.apk";
const iosTfUrl = "https://wangbuliao1.fangxuele3.com/app/y1il95";

export const AudienceNew = () => {
  const [isVerificationPassed, setIsVerificationPassed] = useState(false);

  return (
    <Safe
      isVerificationPassed={isVerificationPassed}
      setIsVerificationPassed={setIsVerificationPassed}
    >
      <DownloadPage
        goToIos={() => downloadApp({ url: iosTfUrl })}
        goToAndroid={() => downloadApp({ url: androidUrl })}
        goToTopIos={() => downloadApp({ url: iosTfUrl })}
        pageTitle="小红帽直播app_官方下载"
      />
    </Safe>
  );
};
