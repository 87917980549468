import React from "react";

import Slice7Webp from "../images/Slice7.webp";
import Slice7Png from "../images/Slice7.png";

import Slice8Webp from "../images/Slice8.webp";
import Slice8Png from "../images/Slice8.png";

import Slice9Webp from "../images/Slice9.webp";
import Slice9Png from "../images/Slice9.png";

import Slice10Webp from "../images/Slice10.webp";
import Slice10Png from "../images/Slice10.png";

export const Goddess = () => {
  return (
    <section className="goddess">
      <div className="titH">
        <label className="appName">女神</label>
        <p>
          <span>性感 高清美女</span>
        </p>
      </div>
      <ul>
        <li>
          <a className="down">
            <span className="pic">
              <picture className="picture">
                <source type="image/webp" srcSet={Slice7Webp} />
                <img className="image" src={Slice7Png} />
              </picture>
              <span className="amount">2283</span>
            </span>
            <p>性感少妇令人垂涎</p>
          </a>
        </li>
        <li>
          <a className="down">
            <span className="pic">
              <picture className="picture">
                <source type="image/webp" srcSet={Slice8Webp} />
                <img className="image" src={Slice8Png} />
              </picture>
              <span className="amount">2923</span>
            </span>
            <p>妩媚诱惑女神妲己</p>
          </a>
        </li>
        <li>
          <a className="down">
            <span className="pic">
              <picture className="picture">
                <source type="image/webp" srcSet={Slice9Webp} />
                <img className="image" src={Slice9Png} />
              </picture>
              <span className="amount">1837</span>
            </span>
            <p>情趣护士服cos</p>
          </a>
        </li>
        <li>
          <a className="down">
            <span className="pic">
              <picture className="picture">
                <source type="image/webp" srcSet={Slice10Webp} />
                <img className="image" src={Slice10Png} />
              </picture>
              <span className="amount">1486</span>
            </span>
            <p>连体服的诱惑</p>
          </a>
        </li>
      </ul>
    </section>
  );
};
