import React, { useContext } from "react";

import Bg0111Png from "../images/bg_0111.png";
import Bg0111Webp from "../images/bg_0111.webp";

import SprButtonsPng from "../images/spr-buttons.png";
import SprButtonsWebp from "../images/spr-buttons.webp";

import ArrowDown from "../images/arrow-down.svg";

import NewBgPng from "../images/newBg.png";
import NewBgWebp from "../images/newBg.webp";

import Bg02Png from "../images/bg_02.png";
import Bg02Webp from "../images/bg_02.webp";

import BgAnzhuo from "../images/bg-anzhuo11.jpg";

import { AppContext } from "../../context";

export const TestFlightIOS = ({ goToIos, goToAndroid }) => {
  const { isAndroid, isiOS } = useContext(AppContext);

  return (
    <>
      {isiOS && (
        <section className='test_flight ios' onClick={goToIos}>
          <img src={Bg02Png} width='100%' />
        </section>
      )}

      {isAndroid && (
        <section className='test_flight an' onClick={goToAndroid}>
          <img src={BgAnzhuo} width='100%' />
          <a id='tf_btn_2' className='js_down' href='javascript:;'>
            下载直播管家APP
          </a>
        </section>
      )}
    </>
  );
};
