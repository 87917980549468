import React from "react";
import { Autoplay, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import SliderImage1 from "../images/1.jpg";
import SliderImage2 from "../images/2.jpg";
import SliderImage3 from "../images/3.jpg";
import SliderImage4 from "../images/4.jpg";
import SliderImage5 from "../images/5.jpg";
import SliderImage6 from "../images/6.jpg";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

export const SwiperView = () => {
  return (
    <section className="swiper">
      <Swiper
        pagination
        modules={[Autoplay, Pagination]}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
      >
        <SwiperSlide>
          <img className="swiper-item" alt="slider" src={SliderImage1} />
        </SwiperSlide>
        <SwiperSlide>
          <img className="swiper-item" alt="slider" src={SliderImage2} />
        </SwiperSlide>
        <SwiperSlide>
          <img className="swiper-item" alt="slider" src={SliderImage3} />
        </SwiperSlide>
        <SwiperSlide>
          <img className="swiper-item" alt="slider" src={SliderImage4} />
        </SwiperSlide>
        <SwiperSlide>
          <img className="swiper-item" alt="slider" src={SliderImage5} />
        </SwiperSlide>
        <SwiperSlide>
          <img className="swiper-item" alt="slider" src={SliderImage6} />
        </SwiperSlide>
      </Swiper>
    </section>
  );
};
