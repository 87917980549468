import React from "react";

import SprButtons from "../images/spr-buttons.png";

export const Addr = ({
  letsChatUrl = "https://letschat.newweiwxx.com/index.html",
}) => {
  return (
    <section className='addr'>
      <div className='contact'>
        <a className='down' rel='noreferrer' target='_blank' href={letsChatUrl}>
          <img
            src={SprButtons}
            style={{
              width: "5.963rem",
              position: "relative",
              top: "-2.963rem",
            }}
          />
        </a>
      </div>
      <div class='addr-content'>
        <a target='_blank' href='http://www.zz38.me' rel='noreferrer'>
          <span>游戏地址</span>
          <span class='warning'>http://www.zz38.me</span>
        </a>
        <a target='_blank' href='https://github.com/XHM6/1' rel='noreferrer'>
          <span>回家地址</span>
          <span class='warning'>https://github.com/XHM6/1</span>
        </a>
      </div>
    </section>
  );
};
