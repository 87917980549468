import posthog from "posthog-js";

export function getRefereeId() {
  const locationSearch = window.location.search;
  const urlParams = new URLSearchParams(locationSearch);

  return urlParams.get("invite_id");
}

export function downloadApp({ url }) {
  posthog.capture("Application Download", { referee_id: getRefereeId() });

  window.open(url, "_blank");
}
