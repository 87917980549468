import React from "react";
import { useState } from "react";
import { DownloadPage } from "../download-page/DownloadPage";
import { Safe } from "../safe-page/Safe";
import { downloadApp } from "../utils";

const androidUrl = "/download-link?type=vegan&platform=android&position=top";
const iosTfUrl = "";

export const Yujian = () => {
  const [isVerificationPassed, setIsVerificationPassed] = useState(false);

  return (
    <Safe
      isVerificationPassed={isVerificationPassed}
      setIsVerificationPassed={setIsVerificationPassed}
    >
      <DownloadPage
        goToIos={() => downloadApp({ url: iosTfUrl })}
        goToAndroid={() => downloadApp({ url: androidUrl })}
        goToTopIos={() => downloadApp({ url: iosTfUrl })}
        pageTitle="相遇直播app_官方下载"
      />
    </Safe>
  );
};
