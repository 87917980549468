import React, { useContext } from "react";
import { AppContext } from "../../context";

import FooterLogoPng from "../images/footer-logo.png";
import FooterLogoWebp from "../images/footer-logo.webp";

export const Footer = ({ goToIos, goToAndroid }) => {
  const { isAndroid, isiOS } = useContext(AppContext);

  return (
    <footer>
      <div className="logo-cont">
        <picture>
          <source
            type="image/webp"
            srcSet={FooterLogoWebp}
            className="appLogo"
          />
          <img src={FooterLogoPng} alt="" className="appLogo" />
        </picture>
      </div>
      <p>
        <span>小红帽直播</span>
        <span>您的女神还有5秒抵达战场 →</span>
      </p>
      {isAndroid && (
        <a onClick={goToAndroid} className="an js_down">
          立即下载
        </a>
      )}
      {isiOS && (
        <a onClick={goToIos} className="ios js_down">
          立即下载
        </a>
      )}
    </footer>
  );
};
